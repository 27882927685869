import React, { Fragment, useMemo } from 'react';
import classnames from 'classnames';

import { getCountryName } from '../utils/index';

import { can } from '../can';
import { routes } from '../routes';

import { sizeToString } from '../utils';
import { groupBy } from 'lodash';
import DoorList from '../components/DoorList';
import {
  orderTypes,
  orderStatusLabel,
  orderStatusColors,
  modeLabels,
  lockerStatusLabels,
  doorTypeLabels,
} from '../constants';

import api from '../utils/api';
import { useForm } from '../utils/form';

import { Loading, NotFound, Forbidden } from './PageStatus';
import PageHeader from '../components/common/PageHeader';
import { List, Header, Body, Row, Cell } from '../components/common/List';

import TextArea from '../components/common/TextArea';
import Input from '../components/common/Input';
import Toggle from '../components/common/Toggle';
import Button from '../components/common/Button';
import Select, { indexToOptions, objectToOptions, optionToItem } from '../components/common/Select';

import Label, { labelTypes } from '../components/common/Label';
import ConnectionIndicator from '../components/common/ConnectionIndicator';
import Count from '../components/graphs/Count';

import grid from '../styles/grid.css';
import css from './Locker.css';

const lockerFields = {
  name: ['text', true],
  locationId: ['number', true],
  status: ['text'],
  extras: ['json'],
  preferences: {
    mode: ['text'],
    doorType: ['text'],
    localBrokerIp: ['ip'],
    pickupNationalIdEnabled: ['toggle'],
    pickupNationalIdCheckEnabled: ['toggle'],
    courierPickupCodeRequired: ['toggle'],
    clientAdminControlDoorsEnabled: ['toggle'],
    customizeColors: ['toggle'],
    customizeText: ['toggle'],
    name: ['text'],
    logo: ['text'],
    background: ['text'],
    logoRatio: ['text'],
    supportPhone: ['text'],
    supportEmail: ['text'],
    homePretitle: ['text'],
    homeTitle: ['text'],
    homeInstruction: ['text'],
    btnManualEntry: ['text'],
    help: ['text'],
    btnLogin: ['text'],
    pickupPrompt: ['text'],
    pickupScanPrompt: ['text'],
    nationalIdPromptPretitle: ['text'],
    nationalIdPropmtTitle: ['text'],
    openDoors: ['text'],
    supportTitle: ['text'],
    supportBody: ['text'],
    offlineTitle: ['text'],
    offlineBody: ['text'],
    maintenanceTitle: ['text'],
    maintenanceBody: ['text'],
    outOfServiceTitle: ['text'],
    outOfServiceBody: ['text'],
    frozenTitle: ['text'],
    frozenBody: ['text'],
    thankyou: ['text'],
    wrongCode: ['text'],
    backgroundColor: ['color'],
    textColor: ['color'],
    adminTextColor: ['color'],
    buttonColor: ['color'],
    buttonTextColor: ['color'],
    buttonActiveColor: ['color'],
    buttonBorderColor: ['color'],
    adminBackgroundColor: ['color'],
    primaryButtonBackground: ['color'],
  },
};

export const Locker = ({ match, history }) => {
  const { id } = match.params;
  const { user } = api.auth();

  const { item, actions } = api.lockers.item(id, {}, { history });
  const { index: locationsIndex, actions: locationsActions } = api.locations.index(null, { lazy: true });
  const ordersParams = useMemo(() => ({ lockerId: id, status: '!completed' }), []);
  const { index: ordersIndex, actions: ordersActions } = api.orders.index(ordersParams);
  const form = useForm(lockerFields, item.data, { submit: actions.update });

  const getLocations = async (search) => {
    const index = await locationsActions.get({ search, perPage: 50 });
    return indexToOptions(index, { value: 'id', label: 'name' });
  };

  const onDelete = async () => {
    await actions.delete();
  };

  const doorsBySize = useMemo(() => {
    if (!item.loaded) return;
    const data = item.data.toJS();
    const unorderedDoors = groupBy(
      data.doors.map((curr) => {
        curr.size = sizeToString(curr);
        return curr;
      }),
      'size'
    );
    const doorsBySize = {};
    Object.keys(unorderedDoors)
      .sort((a, b) => {
        let a_ = a
          .replace(/ /g, '')
          .split('×')
          .map((e) => parseInt(e));
        let b_ = b
          .replace(/ /g, '')
          .split('×')
          .map((e) => parseInt(e));
        for (let i = 0; i < a_.length; i++) {
          if (a_[i] < b_[i]) {
            return -1;
          } else if (a_[i] > b_[i]) {
            return 1;
          }
        }
      })
      .forEach(function (key) {
        doorsBySize[key] = unorderedDoors[key];
      });
    return doorsBySize;
  }, [item.data]);

  if (!can(user, 'show', 'lockers')) return <Forbidden />;

  if (item.error && item.status == 404) return <NotFound />;
  if (!item.loaded) return <Loading />;

  const data = item.data.toJS();

  const modeOptions = objectToOptions(modeLabels.obj);
  const doorTypeOptions = objectToOptions(doorTypeLabels.obj);
  const lockerStatusOptions = objectToOptions(lockerStatusLabels.obj, { disabled: ['unregistered'] });

  const isUnregistered = data.status == 'unregistered';
  const isOffline = !isUnregistered && !data.online;
  const country = getCountryName(data.locationCountryCode);
  const canUpdate = can(user, 'update', 'lockers');
  const readOnly = isOffline || !canUpdate;
  const getActive = (arr) =>
    arr.reduce((acc, curr) => (acc = curr.status == 'active' && !curr.reserved && !curr.occupied ? acc + 1 : acc), 0);

  const getSize = (n) => {
    if (n == 0) return 'S';
    else if (n == 1) return 'M';
    else if (n == 2) return 'L';
    else {
      let size = '';
      for (let i = 0; i < n - 2; i++) size = size + 'X';
      return size + 'L';
    }
  };

  if (item.status == 404) return <NotFound />;
  if (!item.loaded) return <Loading />;

  return (
    <main className={css.root}>
      <PageHeader
        section="lockers"
        title={form.getValue('name')}
        dirty={form.dirty && !isOffline}
        autoSave={!isOffline && canUpdate}
        {...item}
        subtitle={
          <Fragment>
            <strong>ID #{data.id}</strong> |{' '}
            {isUnregistered ? ' No registrado' : <ConnectionIndicator online={data.online} />} | {data.locationName}{' '}
            {country ? `| ${country}` : ''}
            {isOffline && canUpdate && (
              <div>
                <i>No es posible hacer cambios cuando el locker está offline.</i>
              </div>
            )}
          </Fragment>
        }>
        {can(user, 'delete', 'lockers') && (
          <Button confirm="¿Eliminar este Locker?" destructive onClick={onDelete}>
            Eliminar
          </Button>
        )}
      </PageHeader>
      <div className={grid.grid}>
        <section className={grid.half}>
          <h2>Órdenes activas en este locker </h2>
          {isUnregistered ? (
            <small>Una vez que el locker esté activo, se mostrarán las órdenes activas asociadas a este locker.</small>
          ) : (
            <List>
              <Header>
                <Cell head>ID</Cell>
                <Cell head>Destinatario</Cell>
                <Cell head>Bulto</Cell>
                <Cell head>Puerta</Cell>
                <Cell head>Estado</Cell>
              </Header>
              {ordersIndex.loaded && ordersIndex.data.size ? (
                <Body>
                  {ordersIndex.data.toJS().map((order, orderIndex) => {
                    const doorNumber = data.doors.find((door) => door.id == order.doorId)?.doorNumber;
                    return (
                      <Row key={`order-${orderIndex}`} onClick={() => history.push(routes('order', { id: order.id }))}>
                        <Cell>{order.id}</Cell>
                        <Cell>{order.pickupName}</Cell>
                        <Cell>{sizeToString(order)}</Cell>
                        {/*
                         * XXX Here we should use the upcoming datapoint that shows if the door is taken.
                         */}
                        <Cell center>
                          {doorNumber ? doorNumber : <span title="Se asignará al entregar el paquete">–</span>}
                        </Cell>
                        <Cell style={{ color: orderStatusColors(order) }}>
                          <strong>{orderTypes(order.orderType)}: </strong>
                          {order.error ? (
                            <Label max={200} type={labelTypes('error')}>
                              {order.error}
                            </Label>
                          ) : (
                            orderStatusLabel(order)
                          )}
                        </Cell>
                      </Row>
                    );
                  })}
                </Body>
              ) : (
                <Body>
                  <Row>
                    <Cell colSpan={5}>No hay órdenes activas asociadas a este locker </Cell>
                  </Row>
                </Body>
              )}
            </List>
          )}

          {can(user, 'update', 'lockers') ? (
            <Fragment>
              <h2>Configuración</h2>
              <Select
                readOnly={readOnly || isUnregistered}
                name="status"
                isClearable={false}
                options={lockerStatusOptions}
                label="Estado"
                form={form}
                note={isUnregistered ? 'Solo puedes cambiar el estado luego de haber sido registrado.' : ''}
                placeholder="..."></Select>
              <Input readOnly={readOnly} name="name" label="Nombre" form={form} placeholder="Nombre del Locker" />
              <Select
                readOnly={readOnly}
                name="locationId"
                label="Ubicación"
                isClearable={false}
                form={form}
                loadOptions={getLocations}
                placeholder="No asignada"
              />

              <h2>Preferencias</h2>

              <Select
                readOnly={readOnly}
                name={['preferences', 'mode']}
                isClearable={false}
                options={modeOptions}
                label="Modo"
                form={form}
                note={'Define el modo en el que opera el locker.'}
                placeholder="..."
              />

              <Select
                readOnly={readOnly}
                name={['preferences', 'doorType']}
                isClearable={false}
                options={doorTypeOptions}
                label="Tipo de puerta"
                form={form}
                note={'Define el protocolo de comunicación con las puertas.'}
                placeholder="..."
              />

              {[doorTypeLabels('maletekV3'), doorTypeLabels('statesman')].includes(
                form.getValue(['preferences', 'doorType'])
              ) ? (
                <Input
                  readOnly={readOnly}
                  name={['preferences', 'localBrokerIp']}
                  label="Ip Broker local"
                  form={form}
                  placeholder={'127.0.0.1'}
                  note={'Dirección IP del broker de comunicación MQTT para las puertas.'}
                />
              ) : null}

              <Toggle
                readOnly={readOnly}
                name={['preferences', 'pickupNationalIdEnabled']}
                label="Solicitar identificación al retirar"
                form={form}
              />
              <Toggle
                readOnly={readOnly}
                name={['preferences', 'pickupNationalIdCheckEnabled']}
                label="Chequear identificación con RUT de la orden al retirar"
                form={form}
              />
              <Toggle
                readOnly={readOnly}
                name={['preferences', 'courierPickupCodeRequired']}
                label="Requerir código para retirar revocación"
                form={form}
              />
              <Toggle
                readOnly={readOnly}
                name={['preferences', 'clientAdminControlDoorsEnabled']}
                label="Habilitar control de puertas para cliente"
                form={form}
              />

              <h2>Personalización</h2>
              <Input
                readOnly={readOnly}
                name={['preferences', 'name']}
                label="Nombre"
                form={form}
                placeholder={'Maletek'}
              />
              <Input
                readOnly={readOnly}
                name={['preferences', 'logo']}
                label="Logo (url)"
                form={form}
                note={'Formatos permitidos: svg o png'}
                placeholder={'Sin logo'}
              />
              <Input
                readOnly={readOnly}
                name={['preferences', 'logoRatio']}
                label="Proporción del logo"
                form={form}
                placeholder={1.5}
                min={0.2}
                max={3}
                step={0.01}
                note="Ayuda a determinar el tamaño en el que se mostrará el logotipo en la pantalla"
              />
              <Input
                readOnly={readOnly}
                name={['preferences', 'background']}
                label="Fondo de pantalla de inicio (url)"
                form={form}
                note={'Formatos permitidos: svg o png'}
                placeholder={'Sin imagen de fondo'}
              />
              <h3>Textos</h3>
              <Toggle
                readOnly={readOnly}
                name={['preferences', 'customizeText']}
                label="Personalizar textos?"
                form={form}
              />

              {form.getValue(['preferences', 'customizeText']) ? (
                <Fragment>
                  <p>
                    <small>
                      Puedes referir otras variables con brackets: <code>{'{{ > variable }}'}</code>, y utilizar tags de
                      html como <code>{'<br/>'}</code>. Los valores en blanco mantendrán el texto por defecto (en gris).
                    </small>
                  </p>
                  <Input
                    readOnly={readOnly}
                    name={['preferences', 'supportPhone']}
                    label="Teléfono de soporte (`supportPhone`)"
                    placeholder="+56 2 2964 2430"
                    form={form}
                  />
                  <Input
                    readOnly={readOnly}
                    name={['preferences', 'supportEmail']}
                    label="Email de Soporte(`supportEmail`)"
                    placeholder="soporte@maletek.cl"
                    form={form}
                  />
                  <Input
                    readOnly={readOnly}
                    name={['preferences', 'homePretitle']}
                    label="Pre-título de bienvenida (`homePretitle`)"
                    form={form}
                    placeholder="Retirando un paquete?"
                  />
                  <Input
                    readOnly={readOnly}
                    name={['preferences', 'homeTitle']}
                    label="Título de bienvenida (`homeTitle`)"
                    form={form}
                    placeholder="Escanea tu código en <br/>el lector"
                  />
                  <Input
                    readOnly={readOnly}
                    name={['preferences', 'homeInstruction']}
                    label="Instrucción página de bienvenida (`homeInstruction`)"
                    form={form}
                    placeholder=""
                  />
                  <Input
                    readOnly={readOnly}
                    name={['preferences', 'btnManualEntry']}
                    label='Botón "Ingreso manual" (`btnManualEntry`)'
                    form={form}
                    placeholder="Ingreso Manual"
                  />
                  <Input
                    readOnly={readOnly}
                    name={['preferences', 'help']}
                    label="Texto ayuda (`help`)"
                    form={form}
                    placeholder="default: En blanco"
                  />
                  <Input
                    readOnly={readOnly}
                    name={['preferences', 'btnLogin']}
                    label='Botón "Login" (`btnLogin`)'
                    form={form}
                    placeholder="Login"
                  />
                  <Input
                    readOnly={readOnly}
                    name={['preferences', 'pickupPrompt']}
                    label="Indicación para pantalla de entrega (`pickupPrompt`)"
                    form={form}
                    placeholder="Ingresa el código que recibiste para retirar tu paquete."
                  />
                  <Input
                    readOnly={readOnly}
                    name={['preferences', 'pickupScanPrompt']}
                    label="Indicación para escanear en pantalla de entrega (`pickupScanPrompt`)"
                    form={form}
                    placeholder="Puedes escanear tu código en el lector en cualquier momento."
                  />
                  {form.getValue(['preferences', 'pickupNationalIdEnabled']) ? (
                    <Fragment>
                      <Input
                        readOnly={readOnly}
                        name={['preferences', 'nationalIdPromptPretitle']}
                        label="Indicación para pantalla de entrega (`nationalIdPromptPretitle`)"
                        form={form}
                        placeholder="Último paso:"
                      />
                      <Input
                        readOnly={readOnly}
                        name={['preferences', 'nationalIdPropmtTitle']}
                        label="Indicación para escanear en pantalla de entrega (`nationalIdPropmtTitle`)"
                        form={form}
                        placeholder="Escanea el código de tu cédula de identidad en el lector."
                      />
                    </Fragment>
                  ) : null}
                  <Input
                    readOnly={readOnly}
                    name={['preferences', 'supportTitle']}
                    label="Título de soporte"
                    form={form}
                    placeholder="Necesitas ayuda? (`supportTitle`)"
                  />
                  <Input
                    readOnly={readOnly}
                    name={['preferences', 'supportBody']}
                    label="Texto de soporte (`supportBody`)"
                    form={form}
                    placeholder="Si necesitas ayuda contáctanos <br /> al <strong>{{supportPhone}}</strong> o a <strong>{{supportEmail}}</strong>."
                  />
                  <Input
                    readOnly={readOnly}
                    name={['preferences', 'offlineTitle']}
                    label="Título de estado offline (`offlineTitle`)"
                    form={form}
                    placeholder="Temporalmente sin conexión"
                  />
                  <Input
                    readOnly={readOnly}
                    name={['preferences', 'offlineBody']}
                    label="Texto de estado offline (`offlineBody`)"
                    form={form}
                    placeholder="{{> supportBody }}"
                  />
                  <Input
                    readOnly={readOnly}
                    name={['preferences', 'maintenanceTitle']}
                    label="Título de en mantención (`maintenanceTitle`)"
                    form={form}
                    placeholder="Locker en mantención"
                  />
                  <Input
                    readOnly={readOnly}
                    name={['preferences', 'maintenanceBody']}
                    label="Texto de en mantención (`maintenanceBody`)"
                    form={form}
                    placeholder="{{> supportBody }}"
                  />
                  <Input
                    readOnly={readOnly}
                    name={['preferences', 'outOfServiceTitle']}
                    label="Título de fuera de servicio (`outOfServiceTitle`)"
                    form={form}
                    placeholder="Temporalmente fuera de servicio"
                  />
                  <Input
                    readOnly={readOnly}
                    name={['preferences', 'outOfServiceBody']}
                    label="Texto de fuera de servicio (`outOfServiceBody`)"
                    form={form}
                    placeholder="{{> supportBody }}"
                  />
                  <Input
                    readOnly={readOnly}
                    name={['preferences', 'frozenTitle']}
                    label="Título de bloqueo (`frozenTitle`)"
                    form={form}
                    placeholder="Demasiados intentos fallidos"
                  />
                  <Input
                    readOnly={readOnly}
                    name={['preferences', 'frozenBody']}
                    label="Texto de bloqueo (`frozenBody`)"
                    form={form}
                    placeholder="Por seguridad debes esperar antes de seguir intentándolo."
                  />
                  <Input
                    readOnly={readOnly}
                    name={['preferences', 'thankyou']}
                    label="Texto de fin de transacción (`thankyou`)"
                    form={form}
                    placeholder="Gracias por usar eLocker {{name}}."
                  />
                  <Input
                    readOnly={readOnly}
                    name={['preferences', 'wrongCode']}
                    label="Texto de código inválido (`wrongCode`)"
                    form={form}
                    placeholder="Código inválido, por favor intenta de nuevo."
                  />
                  <Input
                    readOnly={readOnly}
                    name={['preferences', 'openDoors']}
                    label="Texto de puerta abierta (`openDoors`)"
                    form={form}
                    placeholder="Ya puedes retirar tu paquete. <br/><small>Por favor cierra la puerta para terminar.</small>"
                  />
                </Fragment>
              ) : null}
              <h3>Colores</h3>
              <Toggle name={['preferences', 'customizeColors']} label="Personalizar colores?" form={form} />
              {form.getValue(['preferences', 'customizeColors']) ? (
                <Fragment>
                  <Input readOnly={readOnly} name={['preferences', 'textColor']} label="Color de Texto" form={form} />
                  <Input
                    readOnly={readOnly}
                    name={['preferences', 'adminTextColor']}
                    label="Color de texto de área de administración"
                    form={form}
                  />
                  <Input
                    readOnly={readOnly}
                    name={['preferences', 'backgroundColor']}
                    label="Color de Fondo"
                    form={form}
                  />
                  <Input
                    readOnly={readOnly}
                    name={['preferences', 'adminBackgroundColor']}
                    label="Color de fondo de área administración"
                    form={form}
                  />
                  <h4>Botones</h4>
                  <Input
                    readOnly={readOnly}
                    name={['preferences', 'buttonTextColor']}
                    label="Color de texto de botones"
                    form={form}
                  />
                  <Input
                    readOnly={readOnly}
                    name={['preferences', 'buttonColor']}
                    label="Color de fondo de botones"
                    form={form}
                  />
                  <Input
                    readOnly={readOnly}
                    name={['preferences', 'primaryButtonBackground']}
                    label="Color de fondo de botón primario"
                    form={form}
                  />
                  <Input
                    readOnly={readOnly}
                    name={['preferences', 'buttonBorderColor']}
                    label="Color de borde de botones"
                    form={form}
                  />
                  <Input
                    readOnly={readOnly}
                    name={['preferences', 'buttonActiveColor']}
                    label="Color de fondo de botones en estado activo"
                    form={form}
                  />
                </Fragment>
              ) : null}
            </Fragment>
          ) : null}
        </section>
        <section className={classnames(grid.half, css.doors)}>
          <h2>Puertas disponibles</h2>
          <div className={css.wrapper}>
            {Object.entries(doorsBySize).map(([key, doorArray], index) => (
              <Count
                value={getActive(doorArray)}
                valueLabel={getSize(index)}
                name={key.replace(/ /g, '')}
                total={doorArray.length}
                percentage
                miniBar
                width={130}
                key={`door-${index}`}
              />
            ))}
          </div>
          <DoorList locker={item} lockerActions={actions} orders={ordersIndex.data} readOnly={readOnly} />
          <h3> Extras</h3>
          <TextArea name="extras" type="json" label="Extras" form={form} readOnly={readOnly} />
        </section>
      </div>
    </main>
  );
};

export default Locker;
